import React, { useState, useRef } from "react";
import '../src/style.css'
import $ from "jquery";


function App() {

    const [showPopup, setShowPopup] = useState(false);
    const textToCopyRef = useRef(null);


    const links = {
        X: "https://x.com/beamancoin",
        SOLANA: "https://solscan.io/token/3x3vRA8FJzj1DTUQ4M8FULsXrkzFps6hXxQrSwgQNYta",
        DEX: "https://www.dextools.io/app/en/solana/pair-explorer/CFxErHZ4NoW5x96NRbQjUHrsvNCbXuvW2KAHxYGfxU5j?t=1714822573948",
        TG: "https://t.me/beamancoin",
        COIN: "",
        UNISWAP: "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=3x3vRA8FJzj1DTUQ4M8FULsXrkzFps6hXxQrSwgQNYta&outputSymbol=BEAMAN&inputAmount=0.5&outputAmount=23365895.268631&fixed=in",
        CONTRACT: "3x3vRA8FJzj1DTUQ4M8FULsXrkzFps6hXxQrSwgQNYta"

    }

    const copytext = () => {

        const textToCopy = textToCopyRef.current;
        const popup = document.getElementById('popup');

        if (textToCopy) {
            const textarea = document.createElement('textarea');
            textarea.value = textToCopy.innerText;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            popup.style.display = 'block';
            setTimeout(function () {
                popup.style.display = 'none';
            }, 3000);
        }
    }


    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $(".mob-menu__items .btn").removeClass('active');
            $('.header-menu').removeClass('active');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $(".mob-menu__items .btn").addClass('active');
            $('.header-menu').addClass('active');
        }
    }

    return (
        <>
            <div class="header">
                <div class="logo">
                    <img src="img/logo.svg" alt="Logo" />
                </div>
                <div class="nav">
                    <a href="#about">About the meme</a>
                    <a href="#token">Tokenomics</a>
                </div>
                <a class="btn" href={links.UNISWAP}>
                    Buy $BEAMAN
                </a>
                <div class="mob-menu unselectable">
                    <div class="mob-menu__items" onClick={() => clicker()}>
                        <a class="btn" href={links.UNISWAP}>
                            Buy $BEAMAN
                        </a>
                        <div class="header-soc">
                            <a href="#about">About the meme</a>
                            <a href="#token">Tokenomics</a>
                        </div>
                        <div class="links">
                            <a href={links.TG} target="_blank" rel="noreferrer">
                                <img src="/img/tg.svg" alt="TG" />
                            </a>
                            <a href={links.SOLANA} target="_blank" rel="noreferrer">
                                <img src="/img/sol.svg" alt="SOLANA" />
                            </a>
                            <a href={links.X} target="_blank" rel="noreferrer">
                                <img src="/img/x.svg" alt="X" />
                            </a>
                            <a href={links.DEX} target="_blank" rel="noreferrer">
                                <img src="/img/dex.svg" alt="DEXTOOLS" />
                            </a>
                            <a href={links.COIN} target="_blank" rel="noreferrer">
                                <img src="/img/coin.svg" alt="COINGECKO" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="header__burger" id="buger" onClick={() => burger()}>
                    <span></span>
                </div>
            </div>
            <div className="bg-yellow">
                <div class="info indents">
                    <div class="info-img">
                        <img src="img/man.svg" alt="Be a man" />
                    </div>
                    <div class="info-text">
                        <div class="info-text__title">
                            <img src="img/text.svg" alt="TEXT" />
                        </div>
                        <div class="info-soc">
                            <div class="info-soc__contract">
                                <div class="contract">
                                    <span id="text-to-copy" ref={textToCopyRef}>{links.CONTRACT}</span>
                                    <img id="copy-button" src="/img/copy.svg" alt="Copy" onClick={() => copytext()} />
                                </div>
                                <div class="link-soc">
                                    <a href={links.TG} target="_blank" rel="noreferrer">
                                        <img src="/img/tg.svg" alt="TG" />
                                    </a>
                                    <a href={links.SOLANA} target="_blank" rel="noreferrer">
                                        <img src="/img/sol.svg" alt="SOLANA" />
                                    </a>
                                    <a href={links.X} target="_blank" rel="noreferrer">
                                        <img src="/img/x.svg" alt="X" />
                                    </a>
                                    <a href={links.DEX} target="_blank" rel="noreferrer">
                                        <img src="/img/dex.svg" alt="DEXTOOLS" />
                                    </a>
                                    <a href={links.COIN} target="_blank" rel="noreferrer">
                                        <img src="/img/coin.svg" alt="COINGECKO" />
                                    </a>
                                </div>
                            </div>
                            <div class="info-soc__btns">
                                <a class="btn" href={links.UNISWAP}>
                                    Buy $BEAMAN
                                </a>
                                <a class="btn-wh" href="#about">
                                    Explore meme
                                    <img src="/img/arrow.svg" alt="Arrow" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about indents" id="about">
                <div class="about-text">
                    <div class="about-title">
                        Unfamiliar with this meme? Avoid reading about it. <br></br>
                        be a man.
                    </div>
                    <div class="avout-label">
                        Imagine a middle-aged Bostonian guy, holding court in front of a green screen while a stream of
                        thematically fitting images flashes by. A laid-back soundtrack sets the mood as he dishes out
                        quick-witted, tongue-in-cheek pearls of manly wisdom. In the background, unseen hype men burst into
                        laughter at every punchline.
                        <br></br>
                        This straightforward yet engaging format has struck a chord, catapulting him to online stardom in just a
                        few months. His 10-second videos have amassed a colossal following: 1.5 million fans on TikTok and
                        nearly 300k on Instagram. Across social media, his daily snippets have racked up close to half a billion
                        views. Now, gearing up for his latest venture, he's inviting fans to experience the 'Be a Man'
                        phenomenon live at the Wilbur Theater on August 5th.
                    </div>
                    <div class="about-btns">
                        <div class="btn-grey">
                            <a href="https://www.fox9.com/news/what-does-it-mean-to-be-a-man-bostons-viral-be-a-man-guy-says-have-a-laugh">Read more</a>
                        </div>
                        <div class="btn-grey">
                            <a href="https://www.buzzfeed.com/ryanschocket2/this-be-a-man-tiktok-account-hilariously-drags-toxic">Then even more</a>
                        </div>
                        <div class="btn-grey">
                            <a href="https://imgflip.com/memegenerator/320703299/Be-a-man">Generate a meme</a>
                        </div>
                    </div>
                </div >
                <div class="about-img">
                    <img src="/img/pic.svg" alt="MAN" />
                </div>
            </div >
            <div class="token" id="token">
                <div class="token-title">
                    tokenomics
                </div>
                <div class="token-info">
                    <div class="token-info__info">
                        <div class="total-supply">
                            <p>Total supply</p>
                            <div>
                                <span>69,000,000</span><span class="man">$BEAMAN</span>
                            </div>
                        </div>
                        <div class="sum" style={{ display: "unset"}}>
                        {/* <div class="taxes">
                                <div class="taxes-info">
                                    <p>Final taxes</p>
                                    <div class="quest">
                                        <img src="/img/quest.svg" alt="?" onMouseEnter={() => setShowPopup(true)}
                                            onMouseLeave={() => setShowPopup(false)} />
                                        {
                                            showPopup && <div class="popup-quest">
                                                Taxes before 100 holders are 10/20 to cover some first spends
                                                <img src="/img/rec.png" alt="Rec" class="img-hover" />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <span>1/1%</span>
                            </div> */}
                        <div class="masculinity" >
                            <p>Masculinity</p>
                            <span>100%</span>
                        </div>
                    </div>
                    <div class="contract">
                        <span id="text-to-copy" ref={textToCopyRef}>{links.CONTRACT}</span>
                        <img id="copy-button" src="/img/copy.svg" alt="Copy" onClick={() => copytext()} />
                    </div>
                </div>
                <div class="token-info__img">
                    <img src="/img/pic2.svg" alt="MAN" />
                </div>
            </div>
            <div class="footer">
                <p>This fan project is not officially associated with @bostonbeaman. It's a separate creation inspired by
                    their work and made by independent fans. BE A MAN COIN OPERATES ON THE SOLANA BLOCKCHAIN AS A MEME
                    COIN. THIS INFORMATION DOES NOT CONSTITUTE INVESTMENT ADVICE. THE COIN IS INTENDED FOR ENTERTAINMENT AND
                    EDUCATIONAL PURPOSES ONLY. THE FOUNDERS DISCLAIM LIABILITY FOR ANY LOSSES OR DAMAGES. THE CRYPTOCURRENCY
                    MARKET IS HIGHLY VOLATILE; INVEST AT YOUR OWN RISK. THERE ARE NO GUARANTEES OF PROFIT OR VALUE
                    RETENTION. PRIOR TO INVESTING, CONDUCT THOROUGH RESEARCH. BY PURCHASING, YOU ACKNOWLEDGE THE INHERENT
                    RISKS. THE FOUNDERS HAVE NO OBLIGATION TO UPDATE INFORMATION. COMPLY WITH LOCAL REGULATIONS, AS LAWS
                    VARY BY JURISDICTION. THIS DISCLAIMER IS SUBJECT TO CHANGES WITHOUT NOTICE.</p>
                <span>
                    2024 © BE A MAN COIN. All rights reserved, haha.
                </span>
            </div>
            <div id="popup">
                <div id="popup-content">
                    <p class="">copied!</p>
                </div>
            </div>
        </div>

        </>
    );
}

export default App;
